<template>
  <div>
    <div class="main-container" :style="{'background':imgMainContainer.imgBackGorund}">
      <div class="voucher-container card">
        <div class="card-header"> 
          <div v-if="!showDialog" class="vch-header-form">
            <div class="vch-title-form">
              <h2>Colaboradores</h2>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div v-if="!showDialog" class="vch-body vch-body-form">
            <div class="vch-text-form">
              <p>Esta página é exclusiva para você, colaborador!</p>
              <p>Oferecemos um desconto especial para comprar o produto que</p>
              <p>escolher e receber no endereço de sua preferência.</p>
              <p>Para isso digite seu CPF no campo abaixo e após a validação</p>
              <p>será informado o código de cupom-desconto.</p>
            </div>
            <div class="vch-submit-form">
              <div class="vch-form-content">
                <input        
                  v-mask="'###.###.###-##'"
                  name="cpf"
                  type="text"
                  id="input-cpf" 
                  v-model="cpf"
                  placeholder="Digite seu CPF"
                  class="input-type-marca-fila container"/>
                <div v-if="getAlert" class="text-danger">
                  <p class="vch-submit-txt">{{getAlert}}</p>
                </div>  
              </div>
              <div class="vch-form-content">
                <button id="vchFormButtonCpf" class="btn button-type-marca-fila container" type="button" @click="handleConfirm" :disabled="isLoading">
                  <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Confirmar
                </button>
              </div>
            </div>
          </div>
          <div v-show="showDialog" class="vch-body">  
            <Transition name="slide-fade">
              <div v-if="showDialog" id="vchBodySuccess">
                <i class="bi bi-check-circle"></i>
                <h3>Funcionário cadastrado!</h3>
                <div class="vch-text-success">
                  <p>Olá {{employee.nome}}</p>
                  <p>Você garantiu <b>{{employee.desconto}}% de desconto</b>! Escolha seus produtos</p>
                  <p>e use o cupom abaixo no fechamento do pedido:</p>
                </div>
                <div class="vch-desc-success-content">
                  <div>
                    <b class="vch-desc-success-code">{{employee.cupom}}</b>                                 
                  </div>
                  <div>
                    <VTooltip :triggers="['click']" :autoHide="true">
                      <a @click="copyCodeClipboard(employee.cupom)" class="vch-desc-success-copy">
                        <i class="bi bi-files"></i>
                      </a>
                      <template #popper>
                        {{tooltip.text}}
                      </template>
                    </VTooltip>
                  </div>                  
                </div>
                <div class="vch-text-success" style="margin-bottom: 15px;">
                  <p>O seu voucher estará disponível</p>
                  <p>para utilização em até 15 minutos</p>
                </div>
                <div>
                  <a class="btn button-type-marca-fila" target="_blank" :href="configExt.externalUrlSite">IR PARA A LOJA</a>                  
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <div class="card-footer"> 
          <div class="vch-footer-form">
            <div class="vch-obs-form">
              <p>*O benefício de aniversário é válido durante o mês de aniversário,</p>
              <p>com restrição a 1 compra com no máximo 3 produtos.</p>
              <p>O benefício é renovado a cada aniversário.</p>
            </div>
            <div class="vch-text-form">
              <p>Por se tratar de um beneficio exclusivo é expressamente proibido o</p>
              <p>compartilhamento do CPF e/ou o código do cupom-desconto com outros usuários</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { ConfigImages, ConfigExternals, configColors } from '../helpers/utils'

export default {
    name: 'Voucher',
    data: () => ({
        cpf: '',
        imgMainContainer:{},
        configExt:{},
        tooltip: { text: '' },
        isLoading: false
    }),
    computed: {
        ...mapGetters('voucher',['employee', 'showDialog']),
        ...mapGetters('alert',['getAlert']),
    },
    methods:{
        ...mapActions('voucher',['EmployeeProfile']),
        ...mapActions('alert',['setAlert', 'dropAlert']),
        handleConfirm() {
            if (!this.cpf) {
                this.setAlert({ message: 'Campo CPF é obrigatório' })
            } else if (this.cpf.length < 14) {
                this.setAlert({ message: 'Campo CPF deve ter 11 digitos' })
            } else {
                this.isLoading = true
                this.dropAlert()
                this.EmployeeProfile(this.sanitizeValue(this.cpf))
                  .then(() => this.isLoading = false)
            }
        },
        async copyCodeClipboard(mytext) {
          navigator.clipboard.writeText(mytext)
            .then(() => this.tooltip.text = 'Código Copiado!')
            .catch((err) => this.tooltip.text = 'Erro ao copiar código! ' + err)
        },
        sanitizeValue(value) {
          return value.replace(/[^a-zA-Z0-9]/g,'')
        }
    },
    beforeMount(){
        this.imgMainContainer = ConfigImages('backgrounds','img-vch-backgorund','jpeg')
        this.configExt = ConfigExternals()
        const rootCSS = document.querySelector(':root body')
        rootCSS.style.setProperty('--theme-color', configColors().themeColor)
    }
}
</script>
<style lang="scss">
:root body {
  --theme-color: #000000;
}
</style>
<style lang="css" scoped>
.main-container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100vh;
  min-height: 600px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.voucher-container{
  height: calc(100% - 4em);
  min-height: 600px;
  margin-right: 70px;
}
.vch-header-form{
  align-items: flex-start;
  justify-content: space-between;
}
.vch-title-form{
  margin-top: 40px;
}
.vch-title-form h2, #vchBodySuccess h3{
  color: #0B1F3F;
  font-weight: 700;
  text-align: center;
}
.vch-body{
  text-align: center;
}
.vch-body-form{
  line-height: 1.7;
  font-size: 13px;
}
#vchBodySuccess h3{
  margin-top: 25px;
  margin-bottom: 10px;
}
#vchBodySuccess{
  margin-top: 2.5em;
}
.vch-footer-form{ 
  margin-bottom: 10px;
  text-align: center; 
  line-height: 1;
  font-size: 11px;
}
.vch-text-success{
  font-size: 14px;
}
.vch-text-success b{
  font-weight: 700;
}
.vch-text-form p,
.vch-text-success p,
.vch-obs-form p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.vch-obs-form {
  margin-bottom: 1rem;
}
.vch-submit-form{
  margin-top: 25px;
  align-items: center;
  justify-content: center;
}
.vch-submit-form .vch-form-content{
  padding-left: 30px;
  padding-right: 30px;
}
.input-type-marca-fila{
  text-align: center;
  box-sizing: border-box;
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  border-width: 0 0 1px;
  height: 35px;
}
::-webkit-input-placeholder {
  text-align: center;
}
:-moz-placeholder {
  text-align: center;
}
.button-type-marca-fila{
  box-sizing: border-box;
  cursor: pointer;
  height: 35px;
  border: 1px solid #d9d9d9;
  background: var(--theme-color);
  border-radius: 0;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: .02em;
  text-align: center;
  color: #fff;
  transition: .3s ease-in-out;
}
.button-type-marca-fila:hover{
  background: #757474;
}
#vchFormButtonCpf{
  margin-top: 10px;
}
.vch-submit-txt{
  margin-top: 0;
  margin-bottom: 0;
}
.card{
  border-radius: 0;
}
.card-header{
  background-color: #FFFFFF;
  border-bottom: 0;
}
.card-footer{
    background-color: #FFFFFF;
    border-top: 0;
}
.vch-desc-success-content{
  display: inline-flex;
  margin-top: 15px;
  margin-bottom: 15px;
}
.vch-desc-success-code{
  font-size: 20px;
  font-weight: 700;
}
.vch-desc-success-code{
  display: inline-block;
  vertical-align: middle;
  margin: 10px 5px;
}
.vch-desc-success-copy{
  display: inline-block;
  vertical-align: baseline;
  margin-left: 10px;
  color: var(--theme-color) !important;
}
.vch-desc-success-copy:hover{
  cursor: pointer !important; 
}
.slide-fade-enter-active {
  transition: all 4s ease-out;
}
.slide-fade-leave-active {
  transition: all 0
}
.slide-fade-enter-from, .slide-fade-leave-to {
  opacity: 0;
}
.bi-check-circle{
  font-size: 5rem;
}
.bi-files{
  font-size: 1.5rem;
}
@media only screen and (max-width: 540px) {
  .voucher-container{    
    margin-right: 0px;
  }
  .main-container{
    padding: 25px;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 375px) {
  .button-type-marca-fila{
    margin-top: 8px;
  }
  .vch-desc-success-content{
    display: grid;
  }
}
@media only screen and (max-width: 280px) {
  .vch-desc-success-content{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .vch-desc-success-code{
    margin: 0;
  }
  .vch-submit-form .vch-form-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .bi-check-circle{
    font-size: 3rem;
  }
}
@media only screen and (max-height: 700px) {
  #vchBodySuccess{
    margin-top: 0;
  }
}
</style>
